import { createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const FILTER_KEYS = {
  CLIENT: 'clientId',
  GROUP_TYPE: 'type',
};

const sliceName = 'admin/groups/filters';

export const initialState = {
  [FILTER_KEYS.CLIENT]: ALL_OPTION,
  [FILTER_KEYS.GROUP_TYPE]: ALL_OPTION,
  [SEARCH]: '',
};

const groupsFilterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
    setFilter: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    setSearch: (state, { payload }) => {
      state[SEARCH] = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const { clearFilters, setFilter, setSearch } = groupsFilterSlice.actions;

const getGroupsFiltersState = (state: any) => state[sliceName];

export const getFilters = (state: any) => getGroupsFiltersState(state);
export const getSearch = (state: any) => getGroupsFiltersState(state)[SEARCH];

export default groupsFilterSlice;
