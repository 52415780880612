/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { Checkbox, InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { ATTR_OLIO_MAPPED_FIELDS } from '~/constants/attrMappedFields';
import { ALL_OPTION, CLIENT, MAPPED_OLIO_FIELD, ONLY_INACTIVE, SEARCH } from '~/constants/filterKeysConstants';
import { clearFilters, getAttrsFilters, setFilter } from '~/ducks/admin/attrsFilters';
import { fetchClients } from '~/ducks/admin/clients';
import { getId, getName } from '~/helpers';
import { useAsyncOptions, useDebounce } from '~/lib/hooks';

const mapStateToProps = (state: any) => ({
  attrsFilters: getAttrsFilters(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AttributesFilterBarProps = ConnectedProps<typeof connector>;

function AttributesFilterBar({ clearFilters, attrsFilters, setFilter }: AttributesFilterBarProps) {
  const [search, setSearch] = useState(attrsFilters[SEARCH as keyof typeof attrsFilters]);
  const debouncedSearch = useDebounce(search);
  const showOnlyInactive = attrsFilters[ONLY_INACTIVE];
  const updateFilter = (key: any, val: any) => setFilter({ filterType: key, value: val });
  const handleClearFilters = () => clearFilters();
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
  const handleOptionChange = (value: any, filterType: any) => {
    updateFilter(filterType, value);
  };
  const mappedOlioFieldOptions = useMemo(() => {
    return [ALL_OPTION, ...ATTR_OLIO_MAPPED_FIELDS.map((value) => ({ id: value.value, name: value.label }))];
  }, []);
  const handleSearchClear = () => setSearch('');

  useEffect(() => {
    updateFilter(SEARCH, search);
  }, [debouncedSearch]);

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    optionsToPrepend: [ALL_OPTION],
  });
  const clientValue: any = attrsFilters[CLIENT as keyof typeof attrsFilters];
  const attrValue: any = attrsFilters[MAPPED_OLIO_FIELD as keyof typeof attrsFilters];
  const isFiltered = Object.values<{ id?: string }>(attrsFilters).some((value) => value?.id);
  const showClearAll = isFiltered || showOnlyInactive;

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search Attributes'
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />
      <InputGroup
        {...clientsAsyncOptions}
        label='Client'
        name={CLIENT}
        value={clientValue}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />
      <InputGroup
        label='Mapped Olio Field'
        name={MAPPED_OLIO_FIELD}
        value={attrValue}
        options={mappedOlioFieldOptions}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />
      <CheckboxContainer>
        <Checkbox
          label='Show Only Inactive'
          name={ONLY_INACTIVE}
          value={showOnlyInactive}
          size={18}
          labelSize='16px'
          onChange={handleOptionChange}
        />
      </CheckboxContainer>

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={handleClearFilters}>Clear Filters</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
}

export default connector(AttributesFilterBar);

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const CheckboxContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: fit-content;
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
