import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { AFFILIATE as AFFILIATE_CLIENT_TYPE } from '~/constants/clientTypes';
import { ALL_OPTION } from '~/constants/filterKeysConstants';
import { AFFILIATE as AFFILIATE_GROUP_TYPE } from '~/constants/locationTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { clearFilters, FILTER_KEYS, getFilters, getSearch, setFilter, setSearch } from '~/ducks/admin/groupsFilters';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getApiName, getDisplayName, getId, getName } from '~/helpers';
import { useAsyncOptions, useDebounce } from '~/lib/hooks';
import { Client, LocationType } from '~/models';

import { InputGroup, Select } from '../shared/form';
import { SelectProps } from '../shared/form/Select/Select';
import Search from '../shared/Search';

const mapStateToProps = (state: any) => ({
  filters: getFilters(state),
  search: getSearch(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
  setSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type GroupsFilterBarProps = ConnectedProps<typeof connector>;

function GroupsFilterBar(props: GroupsFilterBarProps) {
  const { filters } = props;
  const [searchValue, setSearchValue] = useState(props.search);
  const debouncedSearch = useDebounce(searchValue);

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: {
      'clientType.not': AFFILIATE_CLIENT_TYPE,
    },
    optionsToPrepend: [ALL_OPTION],
  });

  const groupTypesAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    params: {
      'apiName.not': AFFILIATE_GROUP_TYPE,
      sortBy: 'displayName',
    },
    optionsToPrepend: [ALL_OPTION],
  });

  useEffect(() => {
    props.setSearch(debouncedSearch);
  }, [debouncedSearch]);

  const canClear = filters[FILTER_KEYS.GROUP_TYPE] !== ALL_OPTION || filters[FILTER_KEYS.CLIENT] !== ALL_OPTION;

  const handleClientChange = (option: Client) => props.setFilter({ key: FILTER_KEYS.CLIENT, value: option });
  const handleLocationTypeChange = (option: LocationType) =>
    props.setFilter({ key: FILTER_KEYS.GROUP_TYPE, value: option });
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);
  const handleSearchClear = () => setSearchValue('');

  return (
    <StyledFilterBar>
      <StyledSearch
        placeholder={'Search'}
        value={searchValue}
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />
      <StyledClientInputGroup
        {...clientsAsyncOptions}
        label='Client'
        name='client'
        component={Select}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleClientChange}
        value={filters[FILTER_KEYS.CLIENT]}
      />
      <StyledLocationGroupInputGroup
        {...groupTypesAsyncOptions}
        label={'Group Type'}
        name='groupType'
        component={Select}
        onChange={handleLocationTypeChange}
        getOptionLabel={getDisplayName}
        getOptionValue={getApiName}
        value={filters[FILTER_KEYS.GROUP_TYPE]}
      />
      {canClear && <Link onClick={props.clearFilters}>Clear Filters</Link>}
    </StyledFilterBar>
  );
}

export default connector(GroupsFilterBar);

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const StyledClientInputGroup = styled(InputGroup<SelectProps<Client>>)`
  max-width: 250px;
  min-width: 150px;
  margin-right: 8px;
`;

const StyledLocationGroupInputGroup = styled(InputGroup<SelectProps<LocationType>>)`
  max-width: 250px;
  min-width: 150px;
  margin-right: 8px;
`;

const StyledFilterBar = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const Link = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
`;
